'use-client';
import * as React from 'react';
import classNames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import VectorImage from '@wix/editor-elements-library/src/components/VectorImage/viewer/VectorImage';
import Image from '@wix/thunderbolt-elements/src/components/Image/viewer/Image';
import type { IIconProps } from '../Icon.types';
import { TestIds } from '../constants';
import styles from './style/Icon.scss';

/**
 *
 * Reusable component based on wysiwyg.viewer.components.Icon
 */
const Icon: React.FC<IIconProps> = props => {
  const {
    id,
    renderImage = false,
    imageUri,
    size,
    svgContent = '',
    className,
    svgClassName,
    imageContainerId,
    dataTestId = TestIds.root,
  } = props;
  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      data-testid={dataTestId}
      className={classNames(styles.root, className)}
    >
      {renderImage && imageUri ? (
        <Image
          className={styles.image}
          id={`image-${id}`}
          containerId={imageContainerId!}
          width={size}
          height={size}
          displayMode="fill"
          uri={imageUri}
          name=""
          alt=""
        />
      ) : (
        <VectorImage
          id={`svg-${id}`}
          className={svgClassName}
          svgContent={svgContent}
          shouldScaleStroke={false}
          containerClass={styles.svg}
          withShadow={false}
        />
      )}
    </div>
  );
};

export default Icon;
