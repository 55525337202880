import TinyMenu_TinyMenuFullScreenSkinComponent from '../components/TinyMenu/viewer/skinComps/BaseMenu/TinyMenuFullScreenSkin.skin';
import TinyMenu_TinyMenuFullScreenSkinController from '../components/TinyMenu/viewer/TinyMenu.controller';


const TinyMenu_TinyMenuFullScreenSkin = {
  component: TinyMenu_TinyMenuFullScreenSkinComponent,
  controller: TinyMenu_TinyMenuFullScreenSkinController
};


export const components = {
  ['TinyMenu_TinyMenuFullScreenSkin']: TinyMenu_TinyMenuFullScreenSkin
};

