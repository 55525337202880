'use-client';
import * as React from 'react';
import type { ITinyMenuProps } from '../TinyMenu.types';

const TinyMenu: React.FC<ITinyMenuProps> = props => {
  const { translations, skin: MenuClass, ...restProps } = props;

  return (
    <MenuClass
      wrapperProps={{
        ariaLabel: translations.ariaLabel!,
        ...restProps,
      }}
    />
  );
};

export default TinyMenu;
